import React, { useEffect } from 'react';
import { ResponsivePie } from '@nivo/pie'
import { GetNumberRegisteredPerColor, GetNumberRegisteredPerModelName } from 'Communication/ModelCommunications';
import { INumberRegisteredModelName } from 'Communication/models/Statistics';
import {
  Button,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { List as ListIcon, PieChart, Visibility, VisibilityOff } from '@mui/icons-material';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';

interface IProps {
  make?: Make;
  model?: Model,
  makeName: string,
  modelName?: string,
  hiddenModelNames: string[];
  addHiddenModelNames: (modelName: string[]) => void;
  removeHiddenModelName: (modelName: string) => void;
  firstRegisteredFilter: { fromYear: number, toYear: number };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(430px - ${theme.spacing(4)})`
    },
    viewModeToggle: {
      position: 'absolute',
      top: theme.spacing(1),
      left: theme.spacing(1),
      zIndex: 1
    },
    hideAllOthersBtn: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      zIndex: 1
    },
    listViewList: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: `calc(100% - 64px)`,
      overflow: 'auto'
    }
  })
);

export default function ModelNamePercentagePieChart(props: IProps) {
    const [numberRegisteredPerModelName, setNumberRegisteredPerModelName] = React.useState<INumberRegisteredModelName[]>([]);
    const [pieData, setPieData] = React.useState<any[]>();
    const [isLoading, setIsLoading] = React.useState(true);
    const [viewMode, setViewMode] = React.useState<'pieChart' | 'list'>('pieChart');
    const { make, model, makeName, modelName, hiddenModelNames, addHiddenModelNames, removeHiddenModelName, firstRegisteredFilter } = props;
    const classes = useStyles();

    useEffect(() => {
      initialize();
    }, [make, model, makeName, modelName, hiddenModelNames, firstRegisteredFilter]);

    async function initialize() {
      RefreshNumberRegisteredByModelAndYear();
    }

    async function RefreshNumberRegisteredByModelAndYear() {
      let result = await GetNumberRegisteredPerModelName(
        [...(make?.rdwSearchTerms ?? []), make?.name ?? makeName],
        modelName != undefined ? [...(model?.rdwSearchTerms ?? []), model?.name ?? modelName] : [],
        model?.rdwBlacklistSearchTerms != undefined ? hiddenModelNames.concat(model.rdwBlacklistSearchTerms) : hiddenModelNames,
        firstRegisteredFilter);

      if (modelName === undefined || modelName === '') {
        let groupedValues: INumberRegisteredModelName[] = [];
        let makeNameLength = makeName.length;

        result.forEach(m => {
          let modelName = m.modelName ?? '';
          let makeSubstring = modelName.substr(0, Math.min(makeNameLength, modelName.length));

          if (modelName.toUpperCase().trim() !== makeName.toUpperCase().trim() && makeSubstring.toUpperCase().trim() === makeName.toUpperCase().trim()) {
            modelName = modelName.substring(makeNameLength).trimStart();
          }

          // let formattedModelName = modelName.split(' ')[0].trim();

          let existingNumberRegisteredModelName = groupedValues.find(gp => gp.modelName === modelName);
          if (existingNumberRegisteredModelName === undefined) {
            groupedValues.push({ modelName: modelName, numberRegistered: m.numberRegistered });
          }else {
            existingNumberRegisteredModelName.numberRegistered += m.numberRegistered;
          }
        });

        if (groupedValues.length > 500) {
          let smallestModelGroups = groupedValues.filter(gv => gv.numberRegistered < 50);
          let totalCountSmallGroups = smallestModelGroups.map(smg => smg.numberRegistered).reduce((sum: number, nr: number) => sum + nr, 0);
          groupedValues = groupedValues.filter(gv => gv.numberRegistered >= 50);
          groupedValues.push({ modelName: 'Overige', numberRegistered: totalCountSmallGroups });
        }

        result = groupedValues;
      }

      let resultAsPieDatum = result.map(r => { return { id: r.modelName, value: r.numberRegistered }; })

      setNumberRegisteredPerModelName(result);
      setPieData(resultAsPieDatum);
      setIsLoading(false);
    }

    function hideAllOthers() {
      setIsLoading(true);
      let modelNamesToHide = numberRegisteredPerModelName
        .map(rpmn => rpmn.modelName)
        .filter(mn => !hiddenModelNames.includes(mn) && mn !== model?.name);

      addHiddenModelNames(modelNamesToHide);
    }

    if (pieData == null)
      return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>
    
    const getPercentageStringFromTotal = (value: number) => `${(value / (pieData.reduce((sum: number, d: any) => sum + d.value, 0)) * 100).toPrecision(3)}%`;

    const renderPieChart = () => {
      return (
        <ResponsivePie
            data={pieData}
            margin={{ top: 40, right: 48, bottom: 40, left: 48 }}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
            arcLabelsSkipAngle={8}
            arcLinkLabelsSkipAngle={2}
            arcLinkLabelsDiagonalLength={10}
            arcLinkLabelsThickness={2}
            arcLinkLabelsStraightLength={4}
            borderWidth={2}
            arcLabel={d => getPercentageStringFromTotal(d.value)}
          />
      );
    }

    const renderList = () => {
      const formattedMakeName = (make?.name ?? makeName).toUpperCase();
      return (
        <List disablePadding className={classes.listViewList} dense>
          {hiddenModelNames.filter(hiddenModelName => !numberRegisteredPerModelName.some(visibleModelName => visibleModelName.modelName === hiddenModelName)).map(hiddenModelName => {
            return (
              <React.Fragment key={'visible' + hiddenModelName}>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={hiddenModelName}
                  secondary={`Hidden`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    disabled={isLoading}
                    onClick={() => { setIsLoading(true); removeHiddenModelName(hiddenModelName);} }
                    edge='end'
                    size="large">
                    <VisibilityOff />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              </React.Fragment>
            );
          })}
          {numberRegisteredPerModelName.sort((n1,n2) => +n2.numberRegistered - +n1.numberRegistered).map(model => {
            let isHidden = hiddenModelNames.includes(model.modelName.toString());
            return (
              <React.Fragment key={'hidden' + model.modelName}>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={<Link href={`/model/${formattedMakeName}/${model.modelName}`}>{model.modelName}</Link>}
                  secondary={`${model.numberRegistered} (${getPercentageStringFromTotal(model.numberRegistered)})`}
                />
                {props.model !== undefined && <ListItemSecondaryAction>
                  <IconButton
                    disabled={isHidden || isLoading}
                    onClick={() => { setIsLoading(true); addHiddenModelNames([model.modelName.toString()]); }}
                    edge='end'
                    size="large">
                    <Visibility />
                  </IconButton>
                </ListItemSecondaryAction>}
              </ListItem>
              </React.Fragment>
            );
          })}
        </List>
      );
    }

    return (
        <div style={{ height: 430, position: 'relative' }}>
          <ToggleButtonGroup
            size='small'
            value={viewMode}
            exclusive
            onChange={(e, newViewMode) => setViewMode(newViewMode ?? viewMode)}
            className={classes.viewModeToggle}
          >
            <ToggleButton value='pieChart'><PieChart /></ToggleButton>
            <ToggleButton value='list'><ListIcon /></ToggleButton>
          </ToggleButtonGroup>
          {model !== undefined && <div>
            <Tooltip title="Verberg alle modelnamen die niet exact overeen komen met de gekozen modelnaam">
            <Button disabled={
              isLoading ||
              numberRegisteredPerModelName.length <= 1
              }
              className={classes.hideAllOthersBtn} onClick={hideAllOthers} >Verberg anderen</Button>
            </Tooltip>
          </div>}
          {viewMode == 'pieChart' && renderPieChart()}
          {viewMode == 'list' && renderList()}
        </div>
    )
}