import React, { useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Typography, Paper, Grid, Table, TableBody, TableRow, TableCell, TableContainer, Theme, Divider, IconButton, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { BarChart as BarChartIcon, Cancel, CheckCircle, ExpandMore } from '@mui/icons-material';
import { useParams, Link } from "react-router-dom";
import { FormatModelName, FormatRegistration } from 'Helper/FormatterHelper';
import { GetVehicleDetailsByLicense } from 'Communication/VehicleCommunications';
import { GetEngineDetailsByLicense } from 'Communication/EngineCommunications';
import { GetGearboxDetails } from 'Communication/GearboxCommunications';
import { GetAxleDetailsByLicense } from 'Communication/AxleCommunication';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import moment from 'moment';
import { IEngine } from 'Communication/models/Engine';
import { IGearbox } from 'Communication/models/Gearbox';
import { IAxle } from 'Communication/models/Axle';
import SocialMedia from './Components/SocialMedia'
import EngineSpecifications from './Components/EngineSpecifications';
import Photos from './Components/Photos/Photos';
import Owner from './Components/Owner';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import * as VoertuigVinderApiClient from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import FireAuthHelper from 'Helper/FireAuthHelper';
import Seo from 'Components/Seo/Seo';
import { GetInspectionReports } from 'Communication/InspectionCommunication';
import { IInspectionReport } from 'Communication/models/Inspection';
import { Box } from '@mui/system';
import MakeModelTrimModal from './Components/MakeModelTrimModal';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import { User } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { IRecall } from 'Communication/models/Recall';
import { GetRecallsByRegistration } from 'Communication/RecallCommunication';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(5)
      }
    },
    registration: {
      [theme.breakpoints.down('md')]: {
        fontSize: '5em'
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '4em'
      }
    },
    makeModelContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justfyContent: 'flex-strat',
    },
    makeTitle: {
      fontSize: '3em',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '2em'
      }
    },
    modelTitle: {
      paddingLeft: theme.spacing(2),
      fontSize: '2em',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em'
      }
    },
    titleActionsContainer: {
      paddingTop: theme.spacing(2)
    },
    modelInfoButton: {
      margin: `-0.5em 0 0 ${theme.spacing(1)}`,
      backgroundColor: theme.palette.primary.main,
      color: 'white',

      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      }
    },
    divider: {
      margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
      },
    },
    capitalizedValue: {
      textTransform: 'capitalize'
    },
    tablePartStart: {
      borderTop: `2px ${theme.palette.grey[400]} solid`
    },
    reportDefectCell: {
      padding: 0
    },
    reportDefectAccordion: {
      boxShadow: 'none',
    },
  })
);

export default function Vehicle() {
  AnalyticsHelper.LogScreenVisit("Vehicle");
  const [title, setTitle] = React.useState('');
  const [fireUser, setFireUser] = React.useState<firebase.User>();
  const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
  const [vehicleDetails, setVehicleDetails] = React.useState<IRdwVehicleDetails>();
  const [engineDetails, setEngineDetails] = React.useState<IEngine[]>();
  const [gearboxDetails, setGearboxDetails] = React.useState<IGearbox>();
  const [axleDetails, setAxleDetails] = React.useState<IAxle[]>();
  const [inspectionReports, setInspectionReports] = React.useState<IInspectionReport[]>();
  const [recalls, setRecalls] = React.useState<IRecall[]>([]);
  const [vehicle, setVehicle] = React.useState<VoertuigVinderApiClient.Vehicle>();
  const classes = useStyles();
  let { country = "", license = "" } = useParams();

  const isDutch = country === 'NLD';

  useEffect(() => {
    initialize();
  }, [country, license]);

  FireStoreAuthHelper.OnUserSet(event => setUser(event.detail as User));
  
  firebase.auth().onAuthStateChanged(function (newUser) {
    if (newUser) {
      setFireUser(newUser);
    } else {
      setFireUser(undefined);
    }
  });

  async function initialize() {
    if (vehicle != undefined && vehicle.registration != license) {
      setVehicle(undefined);
    }

    if (vehicleDetails != undefined && vehicleDetails.kenteken != license) {
      setVehicleDetails(undefined);
    }


    setVehicle(await VoertuigVinderApi.getVehicleByRegistrationAndCountryCode(country, license));

    if (country === 'NLD') {
      try {
        let vehicleDetails = await setRdwData();
        setTitle(`${FormatRegistration(license)} | ${vehicleDetails?.merk} ${FormatModelName(vehicleDetails?.merk, vehicleDetails?.handelsbenaming)}`);
      } catch (e) { }
    } else {
      setTitle(`Voertuig ${license}`);
    }
  }

  async function setRdwData() {
    let vehicleDetailsPromse = GetVehicleDetailsByLicense(license);
    let engineDetailsPromise = GetEngineDetailsByLicense(license);
    let axleDetailsPromise = GetAxleDetailsByLicense(license);
    let inspectionReportsPromise = GetInspectionReports(license)
    let recallsPromise = GetRecallsByRegistration(license);

    await Promise.all([vehicleDetailsPromse, engineDetailsPromise, axleDetailsPromise, recallsPromise]);
    let vehicleDetailsResult = await vehicleDetailsPromse;

    setVehicleDetails(vehicleDetailsResult);
    setEngineDetails(await engineDetailsPromise);
    setAxleDetails(await axleDetailsPromise);
    setInspectionReports(await inspectionReportsPromise);
    setRecalls(await recallsPromise);

    let gearboxDetailsPromise = GetGearboxDetails(vehicleDetailsResult.typegoedkeuringsnummer, vehicleDetailsResult.variant, vehicleDetailsResult.uitvoering)
    await Promise.all([gearboxDetailsPromise]);
    let gearBoxDetailsResult = (await gearboxDetailsPromise);
    setGearboxDetails(gearBoxDetailsResult[0]);

    return vehicleDetailsResult;
  }

  function getDescription() {
    if (vehicleDetails == undefined || vehicleDetails.merk == undefined || vehicleDetails.handelsbenaming == undefined) {
      return undefined
    }

    return `${FormatRegistration(license)} | (${license}) | ${vehicleDetails?.merk} ${FormatModelName(vehicleDetails?.merk, vehicleDetails?.handelsbenaming)} Vind alle informatie over deze ${vehicleDetails?.merk} ${FormatModelName(vehicleDetails?.merk, vehicleDetails?.handelsbenaming)} met kenteken ${FormatRegistration(license)} op voertuigvinder.nl`;
  }

  return (
    <div className={classes.container}>
      <Seo title={title} description={getDescription()} />
      <Typography variant="h1" className={classes.registration}>{isDutch ? FormatRegistration(license) : license}</Typography>
      <div className={classes.makeModelContainer}>
        <div>
          <span className={classes.makeTitle}>{vehicle?.makeSummary?.name ?? vehicleDetails?.merk}</span>
          <span className={classes.modelTitle}>{vehicle?.trimSummary?.name ?? vehicle?.modelSummary?.name ?? FormatModelName(vehicleDetails?.merk, vehicleDetails?.handelsbenaming)}</span>
        </div>
        <span className={classes.titleActionsContainer}>
          {isDutch && <Link to={`/model/${vehicleDetails?.merk}/${vehicleDetails?.handelsbenaming}`}>
            <IconButton size='small' className={classes.modelInfoButton}>
              <BarChartIcon />
            </IconButton>
          </Link>}
          <Owner countryCode={country} registration={license} vehicle={vehicle} fireUser={fireUser} />
          {user?.isAdmin && <MakeModelTrimModal countryCode={country} registration={license} vehicle={vehicle} />}
        </span>
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={2} style={{ paddingBottom: 32 }}>
        <Grid item container spacing={4} xs={12}>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={1} style={{ paddingBottom: 16 }}>
            <SocialMedia countryCode={country} vehicle={vehicle} registration={license} />
          </Grid>
          <Grid item xs={12} sm={9} md={9} lg={10} xl={10} style={{ paddingBottom: 16 }}>
            <Photos countryCode={country} vehicle={vehicle} registration={license} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Voertuigspecificaties</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th'>Carrosserievorm</TableCell>
                  <TableCell className={classes.capitalizedValue}>{vehicleDetails?.inrichting}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Primaire kleur</TableCell>
                  <TableCell className={classes.capitalizedValue}>{vehicleDetails?.eerste_kleur.toLowerCase()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Secundaire kleur</TableCell>
                  <TableCell className={classes.capitalizedValue}>{vehicleDetails?.tweede_kleur.toLowerCase()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Massa</TableCell>
                  <TableCell>{vehicleDetails?.massa_ledig_voertuig}kg ({vehicleDetails?.massa_rijklaar}kg)</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Lengte</TableCell>
                  <TableCell>{vehicleDetails?.lengte && vehicleDetails?.lengte > 0 ? `${vehicleDetails?.lengte}cm` : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Breedte</TableCell>
                  <TableCell>{vehicleDetails?.breedte && vehicleDetails?.breedte > 0 ? `${vehicleDetails?.breedte}cm` : 'Onbekend'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Registratie informatie</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th'>Eerste toelating</TableCell>
                  <TableCell>{vehicleDetails?.datum_eerste_toelating != null ? moment(vehicleDetails?.datum_eerste_toelating).calendar('DD-MM-YYYY') : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Eerste afgifte Nederland</TableCell>
                  <TableCell>{vehicleDetails?.datum_eerste_tenaamstelling_in_nederland != null ? moment(vehicleDetails?.datum_eerste_tenaamstelling_in_nederland).calendar('DD-MM-YYYY') : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Meest recente tenaamstelling</TableCell>
                  <TableCell>{vehicleDetails?.datum_tenaamstelling != null ? moment(vehicleDetails?.datum_tenaamstelling).calendar('DD-MM-YYYY') : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>WAM verzekerd</TableCell>
                  {vehicleDetails !== undefined && <TableCell>{vehicleDetails?.wam_verzekerd.toLowerCase() == 'ja' ? <CheckCircle style={{ marginBottom: -7, marginTop: -2, color: 'green' }} /> : <Cancel style={{ marginBottom: -7, marginTop: -2, color: 'red' }} />}</TableCell>}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Prijs</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th'>Catalogusprijs</TableCell>
                  <TableCell>€{vehicleDetails?.catalogusprijs}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Bruto BPM</TableCell>
                  <TableCell>{vehicleDetails?.bruto_bpm != null ? `€${vehicleDetails?.bruto_bpm}` : 'NVT'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <EngineSpecifications vehicleDetails={vehicleDetails} engineDetails={engineDetails} />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Assenspecificaties</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th'>Aantal assen</TableCell>
                  <TableCell>{axleDetails?.length}</TableCell>
                </TableRow>
                {axleDetails?.sort(a => a.axleNumber).map(axle =>
                  <React.Fragment key={axle.axleNumber}>
                    <TableRow className={classes.tablePartStart}>
                      <TableCell component='th'>Locatie</TableCell>
                      <TableCell>{axle?.location == 'V' ? 'Voor' : 'Achter'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th'>Aangedreven</TableCell>
                      <TableCell>{axle?.driven != undefined ? (axle.driven == 'J' ? 'Ja' : 'Nee') : 'Onbekend'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th'>Spoorbreedte</TableCell>
                      <TableCell>{!Number.isNaN(axle?.trackWidth) ? `${axle.trackWidth}mm` : 'Onbekend'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component='th'>Max. belading</TableCell>
                      <TableCell>{!Number.isNaN(axle?.maxAllowedLoadLaw) ? (axle.maxAllowedLoadLaw != axle.maxAllowedLoadTechnical ? `${axle.maxAllowedLoadTechnical}kg(technisch) ${axle.maxAllowedLoadLaw}kg(wettelijk)` : `${axle.maxAllowedLoadLaw}kg`) : 'Onbekend'}</TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Versnellingsbakspecificaties</Typography>
          <TableContainer component={Paper}>
            <Table size='small'>
              <TableBody>
                <TableRow>
                  <TableCell component='th'>Type</TableCell>
                  <TableCell>{gearboxDetails ? (gearboxDetails.type == 'H' ? 'Handmatig' : gearboxDetails.type == 'A' ? 'Automatisch geschakeld' : gearboxDetails.type == 'F' ? 'Vaste overbrengverhouding' : gearboxDetails.type == 'C' ? 'Continu variabel' : gearboxDetails.type == 'G' ? 'Geautomatiseerd' : gearboxDetails.type == 'W' ? 'Wielnaafmotor' : 'Overig') : 'Onbekend'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component='th'>Aantal versnellingen</TableCell>
                  <TableCell>{gearboxDetails && !Number.isNaN(gearboxDetails?.maxGearCount) ? (gearboxDetails.minGearCount != gearboxDetails.maxGearCount ? `${gearboxDetails.minGearCount} tot ${gearboxDetails.maxGearCount}` : gearboxDetails.maxGearCount) : 'Onbekend'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">APK</Typography>
          <TableContainer component={Paper}>
            {inspectionReports != undefined && inspectionReports.length > 0 ?
              <Table size='small'>
                <TableBody>
                  {inspectionReports?.sort(i => i.executedOn).map((report, index) =>
                    <React.Fragment key={report.executedOn.toString() + report.validUntil?.toString()}>
                      <TableRow className={index != 0 ? classes.tablePartStart : ''}>
                        <TableCell component='th'>Uitgevoerd Op</TableCell>
                        <TableCell>{moment(report.executedOn).calendar()}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th'>Geldig tot</TableCell>
                        <TableCell>{report.validUntil != undefined && report.validUntil != 0 && !Number.isNaN(report.validUntil) ? moment(report.validUntil).calendar() : 'Onbekend'}</TableCell>
                      </TableRow>
                      {report.detectedDefects.length > 0 && <TableRow>
                        <TableCell colSpan={2} className={classes.reportDefectCell}>
                          <Accordion disableGutters square={true} className={classes.reportDefectAccordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              id="report-defects"
                            >
                              <span>Bekijk gebreken</span>
                            </AccordionSummary>
                            <AccordionDetails>
                              {report.detectedDefects.map((defect, index) => (
                                <Typography key={defect.description + index}>
                                  {defect.description}
                                </Typography>
                              ))}
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                      </TableRow>}
                    </React.Fragment>
                  )}
                </TableBody>
              </Table> :
              <Box p={1}>Er zijn nog geen APK keuringen voor dit voertuig</Box>
            }
          </TableContainer>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h6">Terugroep actie</Typography>
          <TableContainer component={Paper}>
            {recalls != undefined && recalls.length > 0 ?
              <Table size='small'>
                <TableBody>
                  {recalls?.sort(i => i.publishedOn).map((recall, index) =>
                    <React.Fragment key={recall.reference}>
                      <TableRow className={index != 0 ? classes.tablePartStart : ''}>
                        <TableCell component='th'>Omschrijving</TableCell>
                        <TableCell>{recall.description}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component='th'>Hoogte risico</TableCell>
                        <TableCell>{recall.risk == 'ERN' ? 'Ernstig' : recall.risk == 'GEM' ? 'Gemiddeld' : recall.risk == 'GNR' ? 'Geen risico' : recall.risk == 'LG' ? 'Laag' : 'Nog niet bepaald'}</TableCell>
                      </TableRow>
                      <TableCell colSpan={2} className={classes.reportDefectCell}>
                          <Accordion disableGutters square={true} className={classes.reportDefectAccordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMore />}
                              id="report-defects"
                            >
                              <span>Bekijk meer</span>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0 }}>
                              <TableRow>
                                <TableCell component='th'>Actie</TableCell>
                                <TableCell>{recall.repairDescription}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Gepubliceerd op</TableCell>
                                <TableCell>{moment(recall.publishedOn).calendar()}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Eigenaar geïnformeerd op</TableCell>
                                <TableCell>{moment(recall.ownerInfornmedOn).calendar()}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Door</TableCell>
                                <TableCell>{recall.recaller}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Technische gevolgen</TableCell>
                                <TableCell>{recall.mechanicalConsequence}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Risico</TableCell>
                                <TableCell sx={{whiteSpace: 'pre-line'}}>{recall.consequences.map(c => c.description).join('\n')}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component='th'>Uitgevoerd</TableCell>
                                <TableCell>{recall.status.code == 'P' ? <CheckCircle style={{ marginBottom: -7, marginTop: -2, color: 'green' }} /> : <Cancel style={{ marginBottom: -7, marginTop: -2, color: 'red' }} />}</TableCell>
                              </TableRow>
                            </AccordionDetails>
                          </Accordion>
                        </TableCell>
                    </React.Fragment>
                  )}
                </TableBody>
              </Table> :
              <Box p={1}>Er zijn geen terugroep acties bekend voor dit voertuig</Box>
            }
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}