import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { useNavigate, useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import "firebase/compat/auth";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, TextField, Theme, Typography } from '@mui/material';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { DriveType, FuelType, Trim, User } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { Stack } from '@mui/system';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    })
);

interface IProps {
    isEdit: boolean;
}

export default function ManageTrim(props: IProps) {
    const { isEdit } = props;

    const classes = useStyles();
    const history = useNavigate();

    let { modelId, trimId } = useParams();

    const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
    const [trim, setTrim] = React.useState<Trim>({ id: v4(), modelId: modelId ?? v4(), availableDrives: [], availableFuels: [], name: '', releaseYear: 0 });

    FireStoreAuthHelper.OnUserSet(event => { 
        setUser(event.detail as User);
    });

    useEffect(() => {
        initialize();
    }, [modelId, trimId, isEdit, user]);

    async function initialize() {
        if (!user?.isAdmin) {
            return;
        }

        if (trimId != undefined) {
            let existingTrim = await VoertuigVinderApi.getTrimById(trimId);
            setTrim({ ...existingTrim });
        }
    }

    async function saveChanges() {
        if (trim == undefined)
            return;

        if (trimId != undefined) {
            await VoertuigVinderApi.editTrim(trimId, {
                name: trim.name,
                releaseYear: trim.releaseYear,
                endYear: (trim?.endYear == undefined || trim.endYear < 1800) ? undefined : trim.endYear,
                availableDrives: (trim.availableDrives ?? []),
                availableFuels: (trim.availableFuels ?? []),
                modelId: trim?.modelId,
            });

            await initialize();
        } else {
            await VoertuigVinderApi.addTrim({
                id: trim.id,
                name: trim.name,
                releaseYear: trim.releaseYear,
                endYear: (trim.endYear == undefined || trim.endYear < 1800) ? undefined : trim.endYear,
                availableDrives: (trim.availableDrives ?? []),
                availableFuels: (trim.availableFuels ?? []),
                modelId: trim?.modelId,
            });
        }

        history(`/admin/manage-makes/models/${trim?.modelId}/edit`);
    }

    function handleSelectedDriveChange(event: React.ChangeEvent<HTMLInputElement>, key: DriveType) {
        if (trim?.availableDrives == undefined)
            return;

        if (event.target.checked && !trim?.availableDrives?.includes(key)) {
            setTrim({ ...trim, availableDrives: [...trim.availableDrives, key] })
        } else if (!event.target.checked && trim?.availableDrives?.includes(key)) {
            setTrim({ ...trim, availableDrives: [...trim.availableDrives.filter(a => a != key)] })
        }
    }

    function handleSelectedFuelChange(event: React.ChangeEvent<HTMLInputElement>, key: FuelType) {
        if (trim?.availableFuels == undefined)
            return;

        if (event.target.checked && !trim?.availableFuels?.includes(key)) {
            setTrim({ ...trim, availableFuels: [...trim.availableFuels, key] })
        } else if (!event.target.checked && trim?.availableFuels?.includes(key)) {
            setTrim({ ...trim, availableFuels: [...trim.availableFuels.filter(a => a != key)] })
        }
    }


    if (trim == undefined)
        return <div>Loading...</div>

    return (
        <Box>
            <Typography variant="h1">{isEdit ? trimId ? 'Edit' : 'Add' : 'View'} Trim</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                    <Box p={1} component={Paper}>
                        <Stack spacing={2}>
                            <TextField disabled={!isEdit} label="Name" variant="outlined" value={trim.name ?? ''} onChange={e => setTrim({ ...trim, name: e.target.value })} />
                            <TextField disabled={!isEdit} label="Start production" variant="outlined" value={trim.releaseYear ?? 0 > 0 ? trim.releaseYear : ''} onChange={e => setTrim({ ...trim, releaseYear: e.target.value.length > 0 ?  parseInt(e.target.value) : 0 })} />
                            <TextField disabled={!isEdit} label="End production" variant="outlined" value={trim.endYear  ?? 0 > 0 ? trim.endYear : ''} onChange={e => setTrim({ ...trim, endYear: e.target.value.length > 0 ? parseInt(e.target.value) : 0 })} />
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Drives</FormLabel>
                                <FormGroup style={{ flexDirection: 'row' }}>
                                    {(Object.keys(DriveType) as Array<keyof typeof DriveType>).map(key => 
                                            <FormControlLabel control={<Checkbox checked={trim?.availableDrives?.includes(key as DriveType)} onChange={e => handleSelectedDriveChange(e, key as DriveType)} name={key} key={key} />}label={key}/>)}
                                </FormGroup>
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Fuels</FormLabel>
                                <FormGroup style={{ flexDirection: 'row' }}>
                                    {(Object.keys(FuelType) as Array<keyof typeof FuelType>).map(key => 
                                            <FormControlLabel control={<Checkbox checked={trim?.availableFuels?.includes(key as FuelType)} onChange={e => handleSelectedFuelChange(e, key as FuelType)} name={key} key={key} />}label={key}/>)}
                                </FormGroup>
                            </FormControl>
                            {isEdit && <Button variant='contained' onClick={saveChanges}>Save</Button>}
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}