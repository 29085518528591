import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { useNavigate, useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import "firebase/compat/auth";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Typography } from '@mui/material';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { DriveType, FuelType, Model, ModelSummary, TrimSummary, User } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { Stack } from '@mui/system';
import { AddCircle, BuildCircle } from '@mui/icons-material';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import { sortTrimSummariesByName } from 'Helper/TrimHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    })
);

interface IProps {
    isEdit: boolean;
}

export default function ManageModel(props: IProps) {
    const { isEdit } = props;

    const classes = useStyles();
    const history = useNavigate();

    let { makeId, modelId } = useParams();

    const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
    const [model, setModel] = React.useState<Model>({ id: v4(), makeId: makeId ?? v4(), availableDrives: [], availableFuels: [], rdwSearchTerms: [], rdwBlacklistSearchTerms: [], name: '', description: '', releaseYear: 0, endYear: 0 });
    const [TrimSummaries, setTrimSummaries] = React.useState<TrimSummary[]>([]);

    FireStoreAuthHelper.OnUserSet(event => { 
        setUser(event.detail as User);
    });

    useEffect(() => {
        initialize();
    }, [makeId, modelId, isEdit, user]);

    async function initialize() {
        if (!user?.isAdmin) {
            return;
        }

        if (modelId != undefined) {
            let existingModel = await VoertuigVinderApi.getModelById(modelId);
            setModel({ ...existingModel });

            let trimSummariesResult = await VoertuigVinderApi.getTrimSummariesByModelId(modelId);
            setTrimSummaries(sortTrimSummariesByName(trimSummariesResult));
        }
    }

    async function saveChanges() {
        if (model == undefined)
            return;

        if (modelId != undefined) {
            await VoertuigVinderApi.editModel(modelId, {
                name: model.name,
                description: model.description,
                releaseYear: model.releaseYear,
                endYear: (model.endYear == undefined || model.endYear < 1800) ? undefined : model.endYear,
                availableDrives: (model.availableDrives ?? []),
                availableFuels: (model.availableFuels ?? []),
                makeId: model.makeId,
                rdwSearchTerms: model.rdwSearchTerms.filter(s => s.length > 0),
                rdwBlacklistSearchTerms: model.rdwBlacklistSearchTerms.filter(s => s.length > 0),
            });

            await initialize();
        } else {
            await VoertuigVinderApi.addModel({
                id: model.id,
                name: model.name,
                description: model.description,
                releaseYear: model.releaseYear,
                endYear: (model.endYear == undefined || model.endYear < 1800) ? undefined : model.endYear,
                availableDrives: (model.availableDrives ?? []),
                availableFuels: (model.availableFuels ?? []),
                makeId: model.makeId,
                rdwSearchTerms: model.rdwSearchTerms.filter(s => s.length > 0),
                rdwBlacklistSearchTerms: model.rdwBlacklistSearchTerms.filter(s => s.length > 0),
            });
        }

        history(`/admin/manage-makes/${model?.makeId}`);
    }

    function handleSelectedDriveChange(event: React.ChangeEvent<HTMLInputElement>, key: DriveType) {
        if (model?.availableDrives == undefined)
            return;

        if (event.target.checked && !model?.availableDrives?.includes(key)) {
            setModel({ ...model, availableDrives: [...model.availableDrives, key] })
        } else if (!event.target.checked && model?.availableDrives?.includes(key)) {
            setModel({ ...model, availableDrives: [...model.availableDrives.filter(a => a != key)] })
        }
    }

    function handleSelectedFuelChange(event: React.ChangeEvent<HTMLInputElement>, key: FuelType) {
        if (model?.availableFuels == undefined)
            return;

        if (event.target.checked && !model?.availableFuels?.includes(key)) {
            setModel({ ...model, availableFuels: [...model.availableFuels, key] })
        } else if (!event.target.checked && model?.availableFuels?.includes(key)) {
            setModel({ ...model, availableFuels: [...model.availableFuels.filter(a => a != key)] })
        }
    }

    function onRdwChange(value: string, index: number) {
        let values = [...(model?.rdwSearchTerms ?? [])];
        values[index] = value;
        setModel({...model, rdwSearchTerms: values})
    }

    function addRdw() {
        setModel({...model, rdwSearchTerms: [...(model?.rdwSearchTerms ?? []), '']})
    }

    function onRdwBlacklistChange(value: string, index: number) {
        let values = [...(model?.rdwBlacklistSearchTerms ?? [])];
        values[index] = value;
        setModel({...model, rdwBlacklistSearchTerms: values})
    }

    function addRdwBlacklist() {
        setModel({...model, rdwBlacklistSearchTerms: [...(model?.rdwBlacklistSearchTerms ?? []), '']})
    }


    if (model == undefined)
        return <div>Loading...</div>

    return (
        <Box>
            <Typography variant="h1">{isEdit ? modelId ? 'Edit' : 'Add' : 'View'} Model</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                    <Box p={1} component={Paper}>
                        <Stack spacing={2}>
                            <TextField disabled={!isEdit} label="Name" variant="outlined" value={model.name ?? ''} onChange={e => setModel({ ...model, name: e.target.value })} />
                            <TextField disabled={!isEdit} multiline label="Description" variant="outlined" value={model.description ?? ''} onChange={e => setModel({ ...model, description: e.target.value })} />
                            <TextField disabled={!isEdit} label="Start production" variant="outlined" value={model.releaseYear ?? 0 > 0 ? model.releaseYear : ''} onChange={e => setModel({ ...model, releaseYear: e.target.value.length > 0 ?  parseInt(e.target.value) : 0 })} />
                            <TextField disabled={!isEdit} label="End production" variant="outlined" value={model.endYear  ?? 0 > 0 ? model.endYear : ''} onChange={e => setModel({ ...model, endYear: e.target.value.length > 0 ? parseInt(e.target.value) : 0 })} />
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Drives</FormLabel>
                                <FormGroup style={{ flexDirection: 'row' }}>
                                    {(Object.keys(DriveType) as Array<keyof typeof DriveType>).map(key => 
                                            <FormControlLabel control={<Checkbox checked={model?.availableDrives?.includes(key as DriveType)} onChange={e => handleSelectedDriveChange(e, key as DriveType)} name={key} key={key} />}label={key}/>)}
                                </FormGroup>
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Fuels</FormLabel>
                                <FormGroup style={{ flexDirection: 'row' }}>
                                    {(Object.keys(FuelType) as Array<keyof typeof FuelType>).map(key => 
                                            <FormControlLabel control={<Checkbox checked={model?.availableFuels?.includes(key as FuelType)} onChange={e => handleSelectedFuelChange(e, key as FuelType)} name={key} key={key} />}label={key}/>)}
                                </FormGroup>
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">RDW <IconButton onClick={addRdw}><AddCircle fontSize='small' /></IconButton></FormLabel>
                                {model.rdwSearchTerms?.map((value, i) => {
                                    return <TextField disabled={!isEdit} variant="standard" value={value} onChange={e => onRdwChange(e.target.value, i)} />
                                })}
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">RDW Blacklist<IconButton onClick={addRdwBlacklist}><AddCircle fontSize='small' /></IconButton></FormLabel>
                                {model.rdwBlacklistSearchTerms?.map((value, i) => {
                                    return <TextField disabled={!isEdit} variant="standard" value={value} onChange={e => onRdwBlacklistChange(e.target.value, i)} />
                                })}
                            </FormControl>
                            {isEdit && <Button variant='contained' onClick={saveChanges}>Save</Button>}
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Box p={1} component={Paper}>
                        <List>
                            {TrimSummaries.map(modelSummary => {
                                return (
                                    <ListItem key={modelSummary.id}
                                        secondaryAction={
                                            <IconButton href={`/admin/manage-makes/trims/${modelSummary.id}/edit`} edge="end" aria-label="delete">
                                                <BuildCircle />
                                            </IconButton>
                                        }>
                                        <ListItemText primary={modelSummary.name} />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Button disabled={modelId == undefined} fullWidth variant='contained' href={`/admin/manage-makes/models/${modelId}/trims/add`}>Add</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}