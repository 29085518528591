import React from 'react';
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  TextField,
  Theme,
  Button,
  Box,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Info } from '@mui/icons-material';
import "firebase/compat/auth";
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import RegistrationAutocomplete from 'Components/RegistrationAutocomplete/RegistrationAutocomplete';
import Seo from 'Components/Seo/Seo';
import { GetRandomBackgroundUrl } from 'Helper/BackgroundImageHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    banner: {
      backgroundImage: `url(${GetRandomBackgroundUrl()})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'space',
      marginBottom: theme.spacing(2),
      marginLeft:  theme.spacing(-4),
      height: 260,
      width: '100vw',

      [theme.breakpoints.down('md')]: {
        marginLeft:  theme.spacing(-1),
        height: 130,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        height: 80,
      }
    },
    cardInput: {
      margin: `${theme.spacing(1)} 0`
    },
  })
);

export default function Home() {
  const registrationAutocompleteRef = React.useRef<any>()
  const [infoCardMake, setInfoCardMake] = React.useState("");
  const [infoCardModel, setInfoCardModel] = React.useState("");
  const classes = useStyles();
  const history = useNavigate();

  function ToMakePage() {
    var formattedMake = infoCardMake.toUpperCase().trim().replaceAll('&', '%26').replaceAll('/', '%2F');
    AnalyticsHelper.LogEvent("make_search", { "make": formattedMake });
    history(`/make/${formattedMake}`);
  }

  function ToMakeModelPage() {
    var formattedModelInfoCardMake = infoCardMake.toUpperCase().trim().replaceAll('&', '%26').replaceAll('/', '%2F');
    var formattedModelInfoCardModel = infoCardModel.toUpperCase().trim().replaceAll('&', '%26').replaceAll('/', '%2F');
    if (formattedModelInfoCardModel.length == 0){
      ToMakePage();
      return;
    }

    AnalyticsHelper.LogEvent("make_model_search", { "make": formattedModelInfoCardMake, "model": formattedModelInfoCardModel });
    history(`/model/${formattedModelInfoCardMake}/${formattedModelInfoCardModel}`);
  }
  
  return (
    <Box pb={6} style={{ overflowX: 'visible' }}>
      <Seo
        title="Voertuigvinder.nl - De site voor alles voertuigen"
        description="Vind alles wat je maar kan willen over auto's. Doe een gratis kenteken check en bekijk foto's, APK informatie en meer over alles op kenteken in Nederland. Bekijk alle informatie over automerken en modellen, ontdek verkoopcijfers en hoeveel er in Nederland geregistreerd zijn in Nederland." />
      <Box className={classes.banner}/>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={6} lg={3}>
          <Card>
            <CardContent>
              <Typography variant='h4' gutterBottom>
                Kenteken vinden
              </Typography>
              <Typography variant="body2" component="p">
                Zoek een voertuig op kenteken en ontdek alle informatie over dit voertuig, zoals de leeftijd, het merk, model, brandstof, APK geschiedenis en meer.
              </Typography>
              <div>
                <RegistrationAutocomplete ref={registrationAutocompleteRef} className={classes.cardInput} />
              </div>
              <br/>
              <div>
                <Button variant="contained" color="primary" onClick={() => registrationAutocompleteRef?.current?.toCurrentRegistration == undefined ? null : registrationAutocompleteRef.current.toCurrentRegistration()} fullWidth>Zoeken</Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card >
            <CardContent>
              <Typography variant='h4' gutterBottom>
                Bekijk alle merken
              </Typography>
              <Typography variant="body2" component="p">
                Bekijk een overzicht van alle automerken in Nederland. Mischien vind je wel een automerk waar je nog nooit van gehoord hebt. Ontdek de merken en vind alles over de verkoopcijfers, populairste modellen, nieuwste auto's op kenteken en meer.
              </Typography>
              <br/>
              <div>
                <Button variant="contained" color="primary" href='/make-overview' fullWidth>Bekijk</Button>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
            <CardContent>
              <form onSubmit={ToMakeModelPage}>
              <Typography variant='h4' gutterBottom>
                Merk/Model vinden
              </Typography>
              <Typography variant="body2" component="p">
                Informatie over een specifiek merk of voertuigmodel, zoals de populairste kleur, het oudste kenteken of hoeveel er nu geregistreerd zijn in Nederland.
              </Typography>
              <div>
                <TextField
                  variant="standard"
                  className={classes.cardInput}
                  value={infoCardMake}
                  onChange={e => setInfoCardMake(e.target.value)}
                  fullWidth
                  required
                  label="Merk"
                  placeholder='Volkswagen, Toyota, Ferrari...'
                  InputLabelProps={{ shrink: true }} />
                <TextField
                  variant="standard"
                  className={classes.cardInput}
                  value={infoCardModel}
                  onChange={e => setInfoCardModel(e.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end"><Tooltip title='Hierbij zal gezocht worden naar voertuigen waarbij de modelnaam de ingevoerde waarde bevat. Modelnamen van voertuigen kunnen handmatig zijn ingevuld, hierdoor kan het zelfde model onder verschillende namen vernoemd zijn. Zo bestaat er bijvoorbeeld zowel "land cruiser" als "landcruiser". In dit voorbeeld zou de zoekterm "land" of "cruiser" het meest complete resultaat geven.'><Info /></Tooltip></InputAdornment>
                  }}
                  fullWidth
                  label="Modelnaam"
                  placeholder='Golf, Aygo, 812 Superfast'
                  InputLabelProps={{ shrink: true }} />
              </div>
              <br/>
              <div>
                <Button type='submit' variant="contained" color="primary" fullWidth>Zoeken</Button>
              </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Card>
            <CardContent>
              <form onSubmit={() => history('/national-fleet')}>
              <Typography variant='h4' gutterBottom>
                Nederlands wagenpark
              </Typography>
              <Typography variant="body2" component="p">
                Benieuwd naar het aantal geïmporteerde auto's in Nederland en wat de snelste, oudste of duurste auto van Nederland is? Wat is het populairste automerk? Worden er al meer elektrische dan benzine auto's verkocht? Je vind het hier.
              </Typography>
              <br/>
              <div>
                <Button type='submit' variant="contained" color="primary" fullWidth>Ontdek</Button>
              </div>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}