import React, { useEffect } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";
import {
  Theme,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  CardMedia,
  CardActions,
  CardHeader,
  Avatar,
  Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import "firebase/compat/auth";
import FireAuthHelper from 'Helper/FireAuthHelper';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { AdminVehicleSpot } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      paddingTop: '60%',
      backgroundSize: 'contain'
    },
  })
);

export default function ApprovePhotos() {
  const [todoPhotos, setTodoPhotos] = React.useState<AdminVehicleSpot[]>([]);
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [photoPaths, setPhotoPaths] = React.useState<string[]>([]);
  const classes = useStyles();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    await new Promise(resolve => setTimeout(resolve, 500));

    let user = FireAuthHelper.GetUser();

    if (!user?.isAdmin) {
      return;
    }

    let result = (await VoertuigVinderApi.getVehicleSpotsForAdmin()).filter(s => s.photoId?.includes('.jpg'));
    setTodoPhotos(result);
    await getPhotoLinks(result);
  }

  async function getPhotoLinks(todoPhotos: AdminVehicleSpot[]) {
    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots');

    if (storageRef === undefined) {
      setStorageRef(activeStorageRef);
    }

    let photoPromises = todoPhotos?.flatMap(spot => spot.photos.map(photo => activeStorageRef.child(photo.photoId!).getDownloadURL())) ?? [];
    const photoPathsResult: string[] = await Promise.all(photoPromises);
    setPhotoPaths(photoPathsResult);
  }

  async function approvePhoto(spotId: string) {
    await VoertuigVinderApi.approveVehicleSpotById(spotId);

    let todoPhotosClone = todoPhotos.filter(tp => tp.vehicleSpotId !== spotId);
    setTodoPhotos(todoPhotosClone);
  }

  async function denyPhoto(spotId: string) {
    await VoertuigVinderApi.denyVehicleSpotById(spotId, { reason: 'Denied' });

    let todoPhotosClone = todoPhotos.filter(tp => tp.vehicleSpotId !== spotId);
    setTodoPhotos(todoPhotosClone);
  }

  return (
    <Box pb={6}>
      <Typography variant="h1">Approve photos</Typography>
      <Grid container spacing={2}>
        {todoPhotos.flatMap(todoPhoto => todoPhoto.photos.map((photo, photoIndex) => {
          let photoPath = photoPaths.find(pp => pp.includes(photo.photoId!));
          return (
            <Grid key={todoPhoto.vehicleSpotId} item xs={12} sm={6} lg={3}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar>NO</Avatar>
                  }
                  // action={
                  //   <IconButton aria-label="settings">
                  //     <MoreVertIcon />
                  //   </IconButton>
                  // }
                  title={todoPhoto.spotterUserName}
                  subheader={moment(todoPhoto.addedOn).calendar()}
                />
                <CardMedia
                  className={classes.media}
                  image={photoPath}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {todoPhoto.photoId}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    taken on: {moment(todoPhoto.takenOn).calendar()} <br />
                  city: {todoPhoto.locationCity} <br />
                  country: {todoPhoto.locationCountry} <br />
                  registration: <Link href={`/vehicle/${todoPhoto.vehicleCountryCode}/${todoPhoto.vehicleRegistration}`}>{todoPhoto.vehicleRegistration}</Link>
                  </Typography>
                </CardContent>{photoIndex == todoPhoto.photos.length - 1 &&
                  <CardActions>
                    <Button size="small" color="primary" onClick={() => approvePhoto(todoPhoto.vehicleSpotId!)}>
                        Approve
                    </Button>
                      <Button size="small" color="primary" onClick={() => denyPhoto(todoPhoto.vehicleSpotId!)}>
                        Deny
                    </Button>
                  </CardActions>
                }
              </Card>
            </Grid>
          );
        }))}
      </Grid>
    </Box>
  );
}