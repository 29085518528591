import { ExecuteGearboxQuery } from './RdwCommunication';
import { IGearbox } from './models/Gearbox';

export async function GetGearboxDetails(typeCode: string, variant: string, uitvoering: string): Promise<IGearbox[]> {
    let selectQuery = `$select=typegoedkeuringsnummer as EuTypeInspectionCode, codeuitvoeringtgk as eegImplementationCode, codetypeversnellingsbak as type, aantalversnellingenondergrens as minGearCount, AantalVersnellingenBovengrens as maxGearCount`;
    
    let whereStatements: string[] = [];
    if (typeCode.length > 0)
        whereStatements.push(`typegoedkeuringsnummer='${typeCode}'`);

    if (variant.length > 0)
        whereStatements.push(`codevarianttgk='${variant}'`);

    if (uitvoering.length > 0)
        whereStatements.push(`codeuitvoeringtgk='${uitvoering}'`);

    if (whereStatements.length == 0)
        return [];

    let whereQuery = `&$where=${whereStatements.join(' AND ')}`;
    let euResult: IGearbox[] = await ExecuteGearboxQuery(selectQuery + whereQuery);
    if (euResult.length > 0 ) {
        euResult.forEach(element => {
            element.minGearCount = +element.minGearCount;
            element.maxGearCount = +element.maxGearCount;
        });
    
        return euResult;
    }

    whereQuery = `&$where=${whereStatements[0]}`;
    euResult = await ExecuteGearboxQuery(selectQuery + whereQuery);
    if (euResult.length > 0 ) {
        euResult.forEach(element => {
            element.minGearCount = +element.minGearCount;
            element.maxGearCount = +element.maxGearCount;
        });
    
        return euResult;
    }

    whereQuery = `&$where=${whereStatements[1]}`;
    euResult = await ExecuteGearboxQuery(selectQuery + whereQuery);
    if (euResult.length > 0 ) {
        euResult.forEach(element => {
            element.minGearCount = +element.minGearCount;
            element.maxGearCount = +element.maxGearCount;
        });
    
        return euResult;
    }

    whereQuery = `&$where=${whereStatements[2]}`;
    euResult = await ExecuteGearboxQuery(selectQuery + whereQuery);
    if (euResult.length > 0 ) {
        euResult.forEach(element => {
            element.minGearCount = +element.minGearCount;
            element.maxGearCount = +element.maxGearCount;
        });
    
        return euResult;
    }

    return [];
}