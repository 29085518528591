import { RecallQuery, RecallConsequenceQuery, RecallStatusQuery } from './RdwCommunication';
import { IRecall, IRecallConsequence, IRecallStatus } from './models/Recall';

async function GetRecallStatusesByRegistration(registration: string): Promise<IRecallStatus[]> {
    let selectQuery = `$select=referentiecode_rdw as recallReference, code_status as code`;
    let whereQuery = `&$where=kenteken='${registration}'`
    let result: IRecallStatus[] = await RecallStatusQuery(selectQuery + whereQuery);
    return result
}

async function GetRecallsByReferences(recallReference: string[]): Promise<IRecall[]> {
    let selectQuery = `$select=referentiecode_rdw as reference, publicatiedatum_rdw as publishedOn, meldende_producent_distributeur as recaller, omschrijving_defect as description, materi_le_gevolgen as mechanicalConsequence, beschrijving_van_het_herstel as repairDescription, datum_informeren_eigenaar as ownerInfornmedOn, risicobeoordeling_rdw as risk`;
    let whereQuery = `&$where=referentiecode_rdw in ('${recallReference.join(`','`)}')`;
    let result: IRecall[] = await RecallQuery(selectQuery + whereQuery);
    return result
}

async function GetRecallConsequencesByRecallReferences(recallReference: string[]): Promise<IRecallConsequence[]> {
    let selectQuery = `$select=referentiecode_rdw as recallReference, code_mogelijk_gevaar as code, mogelijk_gevaar as description`;
    let whereQuery = `&$where=referentiecode_rdw in ('${recallReference.join(`','`)}')`;
    let result: IRecallConsequence[] = await RecallConsequenceQuery(selectQuery + whereQuery);
    return result
}

export async function GetRecallsByRegistration(registration: string): Promise<IRecall[]> {
    let recallsStatuses = await GetRecallStatusesByRegistration(registration);

    if ((recallsStatuses == undefined || recallsStatuses.length == 0)) {
        return [];
    }

    let recallReferences = recallsStatuses.map(dd => dd.recallReference);
    let recallsPromise = GetRecallsByReferences(recallReferences);
    let consequencesPromise = GetRecallConsequencesByRecallReferences(recallReferences);
    await Promise.all([recallsPromise, consequencesPromise]);
    let recalls = await recallsPromise;
    let recallsConsequences = await consequencesPromise;

    recalls.forEach(recall => {
        let status = recallsStatuses.find(dd => dd.recallReference == recall.reference);
        let consequences = recallsConsequences.filter(c => c.recallReference == recall.reference);

        recall.status = status!;
        recall.consequences = consequences
    });

    return recalls;
}