import React, { useEffect } from 'react';
import { CircularProgress, Divider, Fade, Link, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { FormatModelName, Capitalize, FormatRegistration } from 'Helper/FormatterHelper';
import { IEngnineNetMaxPowerSummary } from 'Communication/models/Engine';
import { IRdwVehicleDetails } from 'Communication/models/Vehicle';
import makeStyles from '@mui/styles/makeStyles';
import { GetOldestVehicles } from 'Communication/VehicleCommunications';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingPlaceholderContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2),
      minHeight: `calc(500px - ${theme.spacing(4)})`
    },
    root: {
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    divider: {
      margin: `${theme.spacing(1)} -${theme.spacing(2)}`
    }
  }
));

export default function Oldest() {
    const classes = useStyles();
    const [oldestVehicles, setOldestVehicles] = React.useState<IRdwVehicleDetails[]>();

    useEffect(() => {
      initialize();
    }, []);

    async function initialize() {
      await refreshOldestVehicles();
    }

    async function refreshOldestVehicles() {
      let vehicles = await GetOldestVehicles('Personenauto', 3);
      setOldestVehicles(vehicles);
    }

    if (oldestVehicles == undefined)
    return <div className={classes.loadingPlaceholderContainer}><Fade in timeout={5000}><CircularProgress size={120} /></Fade></div>

    return (
        <div className={classes.root}>
          <Typography variant="h5">Oudste</Typography>
          <Divider className={classes.divider} />
          <Link href={`/make/${oldestVehicles[0].merk.toUpperCase()}`}>
            <Typography variant="h2">{Capitalize(oldestVehicles[0].merk)}</Typography>
          </Link>
          <Link href={`/model/${oldestVehicles[0].merk.toUpperCase()}/${oldestVehicles[0].handelsbenaming.toUpperCase()}`}>
            <Typography variant="h3">{Capitalize(FormatModelName(oldestVehicles[0].merk, oldestVehicles[0].handelsbenaming))}</Typography>
          </Link>
          <Link href={`/vehicle/NLD/${oldestVehicles[0].kenteken}`}>
            <Typography variant="h5">{FormatRegistration(oldestVehicles[0].kenteken)}</Typography>
          </Link>
          <br />
          <Typography variant="h3">{moment(oldestVehicles[0].datum_eerste_toelating).calendar()}</Typography>
        </div>
    );
}