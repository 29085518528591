import React from 'react';
import { Divider, Grid, Paper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import GeneralNumbers from 'Components/GeneralNumbers/GeneralNumbers';
import RegisteredPerMakePerYearGraph from './Components/RegisteredPerMakePerYearGraph';
import FuelPerYearGraph from './Components/FuelPerYearGraph/FuelPerYearGraph';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import Seo from 'Components/Seo/Seo';
import MostPowerful from 'Scenes/NationalFleet/Components/MostPowerful';
import Oldest from 'Scenes/NationalFleet/Components/Oldest';
import MostExpensive from './Components/MostExpensive';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
        paddingBottom: theme.spacing(6)
      },
      title: {
        fontSize: '4em',
        fontWeight: 600,
        [theme.breakpoints.down('md')]: {
          display: 'block',
        },
      },
      divider: {
        margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
        [theme.breakpoints.down('md')]: {
          margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
        },
      }
  })
);

export default function NationalFleet() {
    AnalyticsHelper.LogScreenVisit("NationalFleet");
    const classes = useStyles();

    return (
      <div className={classes.container}>
        <Seo title='Nederlands wagenpark' description="Benieuwd naar het aantal geïmporteerde auto's in Nederland en wat de snelste, oudste of duurste auto van Nederland is? Wat is het populairste automerk? Worden er al meer elektrische dan benzine auto's verkocht? Je vind het hier." />
        <div>
          <span className={classes.title}>Nederlands wagenpark</span>
        </div>
        <Divider className={classes.divider} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <GeneralNumbers  />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper>
              <MostPowerful />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper>
              <MostExpensive />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <Paper>
              <Oldest />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Paper>
              <RegisteredPerMakePerYearGraph />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} xl={6}>
            <Paper>
              <FuelPerYearGraph />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
}