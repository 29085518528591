import { Divider, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerContainer: {
        height: 32,
        backgroundColor: '#f5f5f5',
        width: '100%',
        bottom: 0,
        left: 0,
    },
    footerItem: {
        padding: theme.spacing(1),
        fontSize: 12
    },
  }),
);

export default function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footerContainer}>
            <Divider />
            <Grid container>
                <Grid className={classes.footerItem} item xs={8}>
                    Nick van der Wel, 2025
                </Grid>
                <Grid style={{textAlign: 'right'}} className={classes.footerItem} item xs={4}>
                    <a href = "mailto: voertuigvinder@gmail.com">Contact</a>
                </Grid>
            </Grid>
        </footer>
    );
}