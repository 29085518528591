import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { useNavigate, useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Autocomplete, Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper, Select, Switch, TextField, Theme, Typography } from '@mui/material';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { Make, MakeSummary, ModelSummary, User, VehicleSummary } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { Stack } from '@mui/system';
import firebase from 'firebase/compat/app';
import countries, { getCountriesSortedByName, ICountry } from 'Helper/CountryHelper';
import { AddCircle, BuildCircle } from '@mui/icons-material';
import FireStoreAuthHelper from 'Helper/FireAuthHelper';
import { sortModelSummariesByName } from 'Helper/ModelHelper';
import RdwRegistrationsManager from '../Components/RdwRegistrationsManager';
import { sortMakeSummariesByName } from 'Helper/MakeHelper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    })
);

interface IProps {
    isEdit: boolean;
}

export default function ManageMake(props: IProps) {
    const { isEdit } = props;

    const classes = useStyles();
    const history = useNavigate();

    let { makeId } = useParams();

    const [user, setUser] = React.useState<User | undefined>(FireStoreAuthHelper?.GetUser());
    const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
    const [make, setMake] = React.useState<Make>({} as Make);
    const [modelSummaries, setModelSummaries] = React.useState<ModelSummary[]>([]);
    const [logoUrl, setLogoUrl] = React.useState<string>();
    const [logoFile, setLogoFile] = React.useState<FileList>();
    const [sortedCountries, setSortedCountries] = React.useState<ICountry[]>([]);
    const [makeSummaries, setMakeSummaries] = React.useState<MakeSummary[]>([]);

    FireStoreAuthHelper.OnUserSet(event => { 
        setUser(event.detail as User);
      });

    useEffect(() => {
        initialize();
    }, [makeId, isEdit, user]);

    async function initialize() {
        if (!user?.isAdmin) {
            return;
        }

        if (isEdit) {
            setSortedCountries(getCountriesSortedByName());
        }

        let makeSummariesResult = await VoertuigVinderApi.getMakeSummaries(true);
        setMakeSummaries(sortMakeSummariesByName(makeSummariesResult));

        if (makeId != undefined) {
            let existingMake = await VoertuigVinderApi.getMakeById(makeId);
            getAndSetLogoUrl(existingMake);

            setMake({ ...existingMake });

            let modelSummariesResult = await VoertuigVinderApi.getModelSummariesByMakeId(makeId);
            setModelSummaries(sortModelSummariesByName(modelSummariesResult));
        } else {
            setMake({ id: v4(), isBrand: true, rdwSearchTerms: [], rdwBlacklistSearchTerms: [], rdwMakesIfCurrentMakeInModel: [], rdwModelBlacklistSearchTerms: [], country: 'NL', name: '', description: '', founder: '', yearFounded: 0 });
        }
    }

    async function getAndSetLogoUrl(make: Make) {
        if (make.logoUrl == undefined)
            return;

        var activeStorageRef = storageRef ?? firebase.storage().ref().child('Makes').child('Logos');

        if (storageRef === undefined) {
            setStorageRef(activeStorageRef);
        }

        let logoUrl = await activeStorageRef.child(make.logoUrl!).getDownloadURL();
        setLogoUrl(logoUrl);
    }

    async function uploadNewLogo(): Promise<string> {
        if (logoFile == undefined) {
            throw 'File not defined';
        }

        var activeStorageRef = storageRef ?? firebase.storage().ref().child('Makes').child('Logos');

        if (storageRef === undefined) {
            setStorageRef(activeStorageRef);
        }

        let fileExtension = logoFile[0].name.split('.').reverse()[0];

        let fileName = `${make?.name}_logo_${(Math.floor(Math.random() * 1000000))}.${fileExtension}`;
        let storageLocation = activeStorageRef!.child(fileName);

        let metadata = {
            contentType: logoFile[0].type,
        };

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = async (e) => {
                let fileString = reader.result as string;
                await storageLocation.putString(fileString, 'data_url', metadata);
                resolve(fileName);
            };
            reader.readAsDataURL(logoFile[0]);
        });
    }

    async function saveChanges() {
        let logoUrl = make?.logoUrl;
        if (logoFile != undefined && logoFile[0] != undefined)
            logoUrl = await uploadNewLogo();

        if (make == undefined)
            return;

        if (makeId != undefined) {
            await VoertuigVinderApi.editMake(makeId, {
                country: make.country,
                description: make.description ?? '',
                founder: make.founder,
                isBrand: make.isBrand,
                logoUrl: logoUrl ?? '',
                name: make.name,
                yearFounded: make.yearFounded,
                rdwSearchTerms: make.rdwSearchTerms?.filter(s => s.length > 0),
                rdwModelBlacklistSearchTerms: make.rdwModelBlacklistSearchTerms?.filter(s => s.length > 0),
                rdwMakesIfCurrentMakeInModel: make.rdwMakesIfCurrentMakeInModel?.filter(s => s.length > 0),
                rdwBlacklistSearchTerms: make.rdwBlacklistSearchTerms?.filter(s => s.length > 0),
            });

            await initialize();
        } else {
            await VoertuigVinderApi.addMake({
                id: make?.id,
                country: make?.country,
                description: make?.description ?? '',
                founder: make?.founder,
                isBrand: make?.isBrand,
                logoUrl: logoUrl ?? '',
                name: make?.name,
                yearFounded: make?.yearFounded,
                rdwSearchTerms: make?.rdwSearchTerms?.filter(s => s.length > 0),
                rdwModelBlacklistSearchTerms: make?.rdwModelBlacklistSearchTerms?.filter(s => s.length > 0),
                rdwMakesIfCurrentMakeInModel: make?.rdwMakesIfCurrentMakeInModel?.filter(s => s.length > 0),
                rdwBlacklistSearchTerms: make?.rdwBlacklistSearchTerms?.filter(s => s.length > 0),
            });

            history(`/admin/manage-makes/${make?.id}`);
        }
    }

    function onRdwChange(value: string, index: number) {
        let values = [...(make?.rdwSearchTerms ?? [])];
        values[index] = value;
        setMake({...make, rdwSearchTerms: values})
    }

    function addRdw() {
        setMake({...make, rdwSearchTerms: [...(make?.rdwSearchTerms ?? []), '']})
    }

    function onRdwModelBlacklistChange(value: string, index: number) {
        let values = [...(make?.rdwModelBlacklistSearchTerms ?? [])];
        values[index] = value;
        setMake({...make, rdwModelBlacklistSearchTerms: values})
    }

    function addRdwModelBlacklist() {
        setMake({...make, rdwModelBlacklistSearchTerms: [...(make?.rdwModelBlacklistSearchTerms ?? []), '']})
    }

    function onRdwMakesIfCurrentMakeInModelChange(value: string, index: number) {
        let values = [...(make?.rdwMakesIfCurrentMakeInModel ?? [])];
        values[index] = value;
        setMake({...make, rdwMakesIfCurrentMakeInModel: values})
    }

    function addRdwMakesIfCurrentMakeInModel() {
        setMake({...make, rdwMakesIfCurrentMakeInModel: [...(make?.rdwMakesIfCurrentMakeInModel ?? []), '']})
    }

    if (make == undefined)
        return <div>Loading...</div>

    return (
        <Box>
            <Typography variant="h1">{isEdit ? makeId ? 'Edit' : 'Add' : 'View'} Make</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                    <Box p={1} component={Paper}>
                        <Stack spacing={2}>
                            <TextField disabled={!isEdit} label="Name" variant="outlined" value={make.name ?? ''} onChange={e => setMake({ ...make, name: e.target.value })} />
                            {(logoUrl || logoFile) && <img style={{ maxWidth: 128, maxHeight: 128 }} src={logoFile ? URL.createObjectURL(logoFile[0]) : logoUrl} />}
                            <div>
                                <Button
                                    disabled={!isEdit}
                                    variant="contained"
                                    component="label"
                                    style={{ marginRight: 8 }}
                                >
                                    Edit Logo
                                    <input
                                        type="file"
                                        hidden
                                        onChange={e => setLogoFile(e.target.files ?? undefined)}
                                    />
                                </Button>
                                {logoFile && logoFile[0].name}
                            </div>
                            <FormControlLabel control={<Switch disabled={!isEdit} checked={make.isBrand} onChange={e => setMake({ ...make, isBrand: e.target.checked })} />} label="Is a brand" />
                            <TextField disabled={!isEdit} multiline label="Description" variant="outlined" value={make.description ?? ''} onChange={e => setMake({ ...make, description: e.target.value })} />
                            <TextField disabled={!isEdit} label="Founder" variant="outlined" value={make.founder ?? ''} onChange={e => setMake({ ...make, founder: e.target.value })} />
                            <TextField disabled={!isEdit} label="Year founded" variant="outlined" type='number' value={make.yearFounded ?? ''} onChange={e => setMake({ ...make, yearFounded: parseInt(e.target.value) })} />
                            <Select
                                variant='outlined'
                                disabled={!isEdit}
                                renderValue={(selected) => countries.find(c => c.iso === selected)?.name}
                                onChange={e => setMake({ ...make, country: e.target.value })}
                                value={make.country}>
                                {sortedCountries.map((country) => (
                                    <MenuItem key={country.ilpcc} value={country.iso}>
                                        <ListItemText primary={`${country.ilpcc}  - ${country.name}`} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">RDW <IconButton onClick={addRdw}><AddCircle fontSize='small' /></IconButton></FormLabel>
                                {make.rdwSearchTerms?.map((value, i) => {
                                    return <TextField disabled={!isEdit} variant="standard" value={value} onChange={e => onRdwChange(e.target.value, i)} />
                                })}
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">RDW Models blacklist <IconButton onClick={addRdwModelBlacklist}><AddCircle fontSize='small' /></IconButton></FormLabel>
                                {make.rdwModelBlacklistSearchTerms?.map((value, i) => {
                                    return <TextField disabled={!isEdit} variant="standard" value={value} onChange={e => onRdwModelBlacklistChange(e.target.value, i)} />
                                })}
                            </FormControl>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormLabel component="legend">Toegestaan RDW merk als dit merk in modelnaam <IconButton onClick={addRdwMakesIfCurrentMakeInModel}><AddCircle fontSize='small' /></IconButton></FormLabel>
                                {make.rdwMakesIfCurrentMakeInModel?.map((value, i) => {
                                    return <Autocomplete
                                        disabled={!isEdit}
                                        fullWidth
                                        options={makeSummaries}
                                        getOptionLabel={(option) => option.name ?? ''}
                                        onChange={(e, v, r, d) => onRdwMakesIfCurrentMakeInModelChange(v?.id ?? '', i)}
                                        value={makeSummaries.find(ms => ms.id == value)}
                                        popupIcon={false}
                                        renderInput={(params) => { params.inputProps.inputMode = 'search'; return (<TextField variant="standard" placeholder='Make' {...params} />); }}
                                />
                                })}
                            </FormControl>
                            {isEdit && <Button variant='contained' onClick={saveChanges}>Save</Button>}
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Box p={1} component={Paper}>
                        <List>
                            {modelSummaries.map(modelSummary => {
                                return (
                                    <ListItem key={modelSummary.id}
                                        secondaryAction={
                                            <IconButton href={`/admin/manage-makes/models/${modelSummary.id}/edit`} edge="end" aria-label="delete">
                                                <BuildCircle />
                                            </IconButton>
                                        }>
                                        <ListItemText primary={modelSummary.name} />
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Button disabled={makeId == undefined} fullWidth variant='contained' href={`/admin/manage-makes/${makeId}/models/add`}>Add</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Box p={1} component={Paper}>
                        {makeId && <RdwRegistrationsManager makeId={makeId} />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}