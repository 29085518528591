import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Divider, Grid, Hidden, Link, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import Seo from 'Components/Seo/Seo';
import { useNavigate } from "react-router-dom";
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import { MakeSummary } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { sortMakeSummariesByName } from 'Helper/MakeHelper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(6)
    },
    title: {
      paddingTop: theme.spacing(1),
      fontSize: '4em',
      fontWeight: 600,
    },
    divider: {
      margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
      },
    }
  })
);

export default function MakeOverview() {
  AnalyticsHelper.LogScreenVisit("Make overview");
  const classes = useStyles();
  const [makes, setMakes] = React.useState<MakeSummary[]>([]);
  const [makeLogoUrls, setMakeLogoUrls] = React.useState<{makeId: string, logoUrl: string }[]>([]);
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [isLoading, setIsLoading] = React.useState(true);
  const history = useNavigate();

  useEffect(() => {
    initialize();
  }, []);

  async function initialize() {
    let makesResult = await VoertuigVinderApi.getMakeSummaries(true);
    setMakes(sortMakeSummariesByName(makesResult));

    setIsLoading(false);

    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Makes').child('Logos');

    let makeLogoUrls = await Promise.all(makesResult.map(async make => {
        if (storageRef === undefined) {
            setStorageRef(activeStorageRef);
        }

        return { makeId: make.id!, logoUrl: await activeStorageRef.child(make.logoUrl!).getDownloadURL()};
    }));

    setMakeLogoUrls(makeLogoUrls);
  }

  return (
    <div className={classes.container}>
      <Seo title="Overzicht van alle automerken" description="Vind alle automerken. Bekijk de verschillende modellen van deze merken en ontdek foto's, statistieken en meer." />
      <div>
        <Typography variant='h1' className={classes.title}>Merken</Typography>
      </div>
      <Divider className={classes.divider} />
      {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
        <CircularProgress />
      </Box>}
      <Box px={{xs: 0, s: 0, md: 1, lg: 2, xl: 10}}>
        <Grid container spacing={2} justifyContent='center'>
          {makes.map(make => {
            let logoUrl = makeLogoUrls.find(m => m.makeId === make.id);

            return (
              <Grid key={make.name} item xs={4} md={3} lg={3} xl={2}>
                <Card>
                  <CardActionArea href={`/make/${make.name}`}>
                    <CardMedia
                      component="img"
                      alt={`${make.name} logo`}
                      height={140}
                      sx={{ padding: {xs: "1em 1em 0 1em", sm: "1em 2em 0 2em"}, width: {xs: "calc(100% - 2em)", sm: "calc(100% - 4em)"}, objectFit: "contain" }}
                      image={logoUrl?.logoUrl}
                    />
                    <CardContent>
                      <Typography component="div" variant='subtitle2' sx={{textAlign: 'center'}}>
                        {make.name}
                      </Typography>  
                    </CardContent>
                    </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </div>
  );
}