import firebase from "firebase/compat/app";
import React, { useEffect } from 'react';
import { Theme, Divider, Paper, Grid, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useParams } from "react-router-dom";
import { FormatMakeName, FormatModelName } from 'Helper/FormatterHelper';
import NumberRegisteredInNetherlandsGraph from './Components/NumberRegisteredInNetherlandsGraph';
import NumberFirstRegisteredGraph from './Components/NumberFirstRegisteredGraph';
import ColorPercentagePieChart from './Components/ColorPercentagePieChart';
import GeneralNumbers from '../../Components/GeneralNumbers/GeneralNumbers';
import RegisteredVehicleList from './Components/RegisteredVehiclesList';
import ModelNamePercentagePieChart from './Components/ModelNamePercentagePieChart';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import Seo from 'Components/Seo/Seo';
import { Make, Model } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import Description from './Components/Description';
// import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: theme.spacing(6)
    },
    headerContainer: {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    titleContainer: {
      display: 'inline-flex',
      alignItems: 'baseline',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
      },
    },
    makeTitle: {
      fontSize: '4em',
      fontWeight: 600,
      color: theme.palette.grey[900],
      textDecoration: 'none',
    },
    divider: {
      margin: `${theme.spacing(2)} -${theme.spacing(4)}`,
      [theme.breakpoints.down('md')]: {
        margin: `${theme.spacing(2)} -${theme.spacing(1)}`,
      },
    },
    makeLogo: {
      maxWidth: 128,
      maxHeight: 128,
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        maxWidth: 96,
        maxHeight: 96,
        marginRight: theme.spacing(2)
      },
    }
  })
);

export default function MakeModel() {
  AnalyticsHelper.LogScreenVisit("MakeModel");
  const [hiddenModelNames, setHiddenModelNames] = React.useState<string[]>([]);
  const [firstRegisteredFilter, setFirstRegisteredFilter] = React.useState<{ fromYear: number, toYear: number }>({ fromYear: 0, toYear: 0 });
  const [isLoading, setIsLoading] = React.useState(true);
  const [make, setMake] = React.useState<Make>();
  const [model, setModel] = React.useState<Model>();
  const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
  const [logoPath, setLogoPath] = React.useState<string>();
  const classes = useStyles();
  let { makeName, modelName } = useParams();

  useEffect(() => {
    initialize();
  }, [makeName, modelName]);

  async function initialize() {
    let make: Make | undefined = undefined;
    if (makeName != undefined && makeName.length > 1) {
      make = await VoertuigVinderApi.searchMake(makeName);
    }

    if (make == undefined) {
      setIsLoading(false);
      return;
    }

    setMake(make);

    if (modelName != undefined && modelName.length > 0) {
      let model = await VoertuigVinderApi.searchModel(make.id!, modelName);
      setModel(model);
    }

    getAndSetLogoUrl(make);
    setIsLoading(false);
  }

  async function getAndSetLogoUrl(make: Make) {
    if (make == undefined || make.logoUrl == undefined || make.logoUrl.length < 3)
      return;

    var activeStorageRef = storageRef ?? firebase.storage().ref().child('Makes').child('Logos');

    if (storageRef === undefined) {
      setStorageRef(activeStorageRef);
    }

    let photoPathResult = await activeStorageRef.child(make.logoUrl).getDownloadURL();
    setLogoPath(photoPathResult);
  }

  function addHiddenModelNames(modelName: string[]) {
    let newHiddenModelNames = [...hiddenModelNames, ...modelName]
    setHiddenModelNames(newHiddenModelNames);
  }

  function removeHiddenModelName(modelName: string) {
    let newHiddenModelNames = hiddenModelNames.filter(mn => mn !== modelName);
    setHiddenModelNames(newHiddenModelNames);
  }

  var formattedMakeName = make?.name ?? FormatMakeName(makeName);
  var uppercaseFormattedMakeName = formattedMakeName.toUpperCase();
  var formattedModelName = model?.name ?? FormatModelName(makeName, modelName);
  var uppercaseFormattedModelName = formattedModelName.toUpperCase();

  if (isLoading)
    return <Seo title={formattedMakeName} description={getDescription()} />;

  function getDescription() {
    if (formattedModelName == undefined || formattedModelName == '') {
      return `${FormatMakeName(makeName)} | Vind alles over de Nederlandse voertuigen van ${formattedMakeName}. Voertuigvinder heeft import aantallen, modellen, kleuren, verkoopcijfers en meer van alle geregistreerde voertuigen van ${formattedMakeName} in Nederland.`;
    }
    return `${FormatMakeName(makeName)} ${formattedModelName} | Vind alles over de Nederlandse ${formattedMakeName} ${formattedModelName} voertuigen. Voertuigvinder heeft import aantallen, kleuren, verkoopcijfers en meer van alle geregistreerde ${formattedMakeName} ${formattedModelName} voertuigen in Nederland.`;
  }

  const isModelView = modelName != undefined;
  return (
    <div className={classes.container}>
      <Seo title={formattedMakeName} description={getDescription()} />
      <div className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          {isModelView ?
            <Typography variant="h1" className={classes.makeTitle}>
              <Link sx={t => {return {color: t.palette.grey[900] }}} href={`/make/${uppercaseFormattedMakeName}`}>{make?.name ?? FormatMakeName(makeName)}</Link> {model?.name ?? formattedModelName}
            </Typography> :
            <Typography variant="h1" className={classes.makeTitle}>{make?.name ?? FormatMakeName(makeName)}</Typography>}
        </div>
        {logoPath && <img alt={`${make?.name} logo`} className={classes.makeLogo} src={logoPath} />}
      </div>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={10} xl={10}>
          <Description make={make} model={model} isModelView={isModelView} />
        </Grid>
      </Grid>
      {((isModelView ? model?.description : make?.description)?.length ?? 0) > 0 && <Divider className={classes.divider} />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} lg={3} xl={3}>
          <GeneralNumbers make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} firstRegisteredFilter={firstRegisteredFilter} />
        </Grid>
        <Grid item xs={12} md={7} lg={6} xl={4}>
          <Paper>
            <ColorPercentagePieChart make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} firstRegisteredFilter={firstRegisteredFilter} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={7} lg={6} xl={4}>
          <Paper>
            <ModelNamePercentagePieChart make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} addHiddenModelNames={addHiddenModelNames} removeHiddenModelName={removeHiddenModelName} firstRegisteredFilter={firstRegisteredFilter} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <RegisteredVehicleList make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} firstRegisteredFilter={firstRegisteredFilter} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <NumberRegisteredInNetherlandsGraph make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} firstRegisteredFilter={firstRegisteredFilter} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <NumberFirstRegisteredGraph make={make} model={model} makeName={uppercaseFormattedMakeName} modelName={uppercaseFormattedModelName} hiddenModelNames={hiddenModelNames} firstRegisteredFilter={firstRegisteredFilter} onFirstRegisteredChange={setFirstRegisteredFilter} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}