import React, { useEffect } from 'react';
import "firebase/compat/storage";
import "firebase/compat/auth";
import { Theme, Box, Typography, Grid, Card, CardContent, Button, Input } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useParams } from "react-router-dom";
import "firebase/compat/auth";
import FireAuthHelper from 'Helper/FireAuthHelper';
import UserGarage from './Components/UserGarage/UserGarage';
import AnalyticsHelper from 'Helper/AnalyticsHelper';
import { User, UserProfile as Profile, EditUserProfile } from 'Communication/VoertuigVinderAPI/VoertuigVinderApiClient';
import { VoertuigVinderApi } from 'Communication/VoertuigVinderAPI/Client';
import UserSpots from './Components/UserSpots/UserSpots';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
  })
);

export default function UserProfile() {
  AnalyticsHelper.LogScreenVisit("UserProfile");
  const [user, setUser] = React.useState<User>();
  const [profile, setProfile] = React.useState<Profile>();
  const [currentUser, setCurrentUser] = React.useState<User>();
  const [isEditing, setIsEditing] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [editUserProfile, setEditUserProfile] = React.useState<EditUserProfile>({} as EditUserProfile);

  const classes = useStyles();
  let { userId = "" } = useParams();

  useEffect(() => {
    initialize();
  }, [userId]);

  async function initialize() {
    await new Promise(resolve => setTimeout(resolve, 500));

    let currentUser = FireAuthHelper.GetUser();
    setCurrentUser(currentUser);

    let user = await VoertuigVinderApi.getUserById(userId);
    let userProfile = await VoertuigVinderApi.getUserProfileById(userId);
    setUser(user);
    setProfile(userProfile);
    setEditUserProfile({ userName: user.userName, description: userProfile.description, photoUrl: userProfile.photoUrl });
  }

  const isProfileOwner = userId === currentUser?.id;

  async function saveChanges(){
    setIsSaving(true);
    await VoertuigVinderApi.editCurrentUserProfile(editUserProfile);
    let userProfile = await VoertuigVinderApi.getUserProfileById(userId);
    setProfile(userProfile);
    setIsSaving(false);
    setIsEditing(false);
  }

  function renderPublicProfile(){
    return(
      <>
        <Typography variant="h1">{user?.userName}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6}>
            <Card><CardContent><Typography sx={{whiteSpace: 'pre-line'}} variant="body1">{profile?.description}</Typography></CardContent></Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserGarage user={user} isProfileOwner={false} />
          </Grid>
          <Grid item xs={12}>
            <UserSpots user={user} isProfileOwner={false} />
          </Grid>
        </Grid>
      </>
    );
  }

  function renderEditProfile(){
    return(
      <>
        <Box py={1}><Input aria-label='gebruikersnaam' sx={{fontSize: 90}} value={editUserProfile.userName} onChange={e => setEditUserProfile({...editUserProfile, userName: e.target.value})} /></Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={6}>
            <Card><CardContent><Input multiline={true} fullWidth value={editUserProfile?.description} onChange={e => setEditUserProfile({...editUserProfile, description: e.target.value})} /></CardContent></Card>
          </Grid>
        </Grid>
        <Box py={1}><Button disabled={isSaving} onClick={saveChanges} variant='contained'>Opslaan</Button></Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UserGarage user={user} isProfileOwner={isProfileOwner} />
          </Grid>
          <Grid item xs={12}>
            <UserSpots user={user} isProfileOwner={isProfileOwner} />
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Box pb={6}>
      {(isProfileOwner && !isEditing) && <Box sx={{position: {xs: "relative", sm: "absolute"}, top: {xs: 6, sm: 100}, right: {xs: undefined, sm: 30}}}>
          <Button variant='outlined' onClick={() => setIsEditing(true)}>Profiel bewerken</Button>
        </Box>}
      {!isEditing && renderPublicProfile()}
      {isEditing && renderEditProfile()}
    </Box>
  );
}