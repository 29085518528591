import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import "firebase/compat/auth";
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useTheme } from '@mui/material/styles';
import { IconButton, ImageList, ImageListItem, ImageListItemBar, Tooltip, Theme } from "@mui/material";
import { StarBorder, Star, Camera } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import AddPhotoDialog from "./Components/AddPhotoDialog";
import { User, Vehicle } from "Communication/VoertuigVinderAPI/VoertuigVinderApiClient";
import { VoertuigVinderApi } from "Communication/VoertuigVinderAPI/Client";
import FireStoreAuthHelper from "Helper/FireAuthHelper";
import PhotoModal from "Components/PhotoModal/PhotoModal";
import { getThumb400Id } from "Helper/PhotoHelper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBar: {
      '& > div': {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`
      }
    },
    spotterButton: {
      margin: `-0.5em 0 0 ${theme.spacing(1)}`,
      padding: 0,
      '& svg': {
          fontSize: 40
      },
      '& img': {
          height: 40,
          borderRadius: 25,
      }
    },
    spotImg: {
      '&:hover': {
        cursor: 'pointer',
      }
    }
  })
);

interface IProps {
  vehicle?: Vehicle;
  registration: string;
  countryCode: string;
}

export default function Photos(props: IProps) {
    const { countryCode, vehicle, registration } = props;
    const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();
    const [photoPaths, setPhotoPaths] = React.useState<string[]>([]);
    const [user, setUser] = React.useState<User | undefined>();
    const [activePhotoId, setActivePhotoId] = React.useState<string>();

    const classes = useStyles();
    const theme = useTheme();
    const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
    const IsMedium = useMediaQuery(theme.breakpoints.down('lg'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const isTiny = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
      initialize();
    }, [countryCode, vehicle, registration]);

    FireStoreAuthHelper.OnUserSet(event => setUser(event.detail as User));

    async function initialize() {
      if (vehicle != undefined && vehicle?.registration !== registration )
        return;

      var activeStorageRef = storageRef ?? firebase.storage().ref().child('Vehicles').child('Spots-Thumb-400');
      
      if (storageRef === undefined){
        setStorageRef(activeStorageRef);
      }

      let photoPromises = vehicle?.vehicleSpots?.flatMap(vs => vs.photos.map(spot => activeStorageRef.child(getThumb400Id(spot.photoId!)).getDownloadURL())) ?? [];
      const photoPathsResult: string[] = await Promise.all(photoPromises);
      setPhotoPaths(photoPathsResult);
      
      setUser(FireStoreAuthHelper?.GetUser());
    }

    const userIsVehicleOwner = user?.id == vehicle?.ownerId && vehicle?.ownerId !== undefined;

    async function makePrimaryPhoto(spotId: string)
    {
      if (!userIsVehicleOwner || spotId == undefined || spotId === '') {
        return;
      }

      await VoertuigVinderApi.setPrimarySpotPhotoById(vehicle!.id!, spotId);

      window.location.reload();
    }

    function getNumberOfCols() {
      if (isExtraLarge) {
        return 5
      }
      if (isLarge) {
        return 4
      }
      if (isTiny) {
        return 1
      }
      if (isSmall) {
        return 2
      }
      if (IsMedium) {
        return 2
      }
      return 3
    }

    return (
        <div>
          <PhotoModal photoId={activePhotoId} onClose={() => setActivePhotoId(undefined)} />
          <ImageList cols={getNumberOfCols()}>
            {vehicle?.vehicleSpots.flatMap(vs => vs.photos.map(vsp => {
              if (photoPaths.length > 0){
                console.log(photoPaths);
              }

              let thumbId = getThumb400Id(vsp.photoId!);
              let photoPath = photoPaths.find(p => p.includes(thumbId));

              let photoMoment = moment(vs.takenOn);
              let isPrimaryPhoto = vsp?.photoId == vehicle?.primaryPhotoId;

              return (
                <ImageListItem key={photoPath}>
                  {photoPath === undefined ? <div>Loading...</div> :
                    <img className={classes.spotImg} alt={`${vehicle?.registration} gespot ${photoMoment.format('D MMMM YYYY')} in ${vs?.locationCity}`} src={photoPath} onClick={() => setActivePhotoId(vsp?.photoId)} />
                  }
                  <ImageListItemBar
                    className={classes.infoBar}
                    title={photoMoment.format('D MMMM YYYY')}
                    subtitle={vs?.locationCity}
                    actionIcon={
                      <div>
                      {userIsVehicleOwner ?
                        <Tooltip title={isPrimaryPhoto ? "Primaire foto" : "Als primaire foto gebruiken"}>
                          <IconButton
                          style={{ padding: 0, marginRight: 12 }}
                          sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                          title="Markeer als primaire foto"
                          onClick={() => isPrimaryPhoto ? () => {} : makePrimaryPhoto(vsp?.vehicleSpotPhotoId!)}
                          >
                            {isPrimaryPhoto ? <Star /> : <StarBorder />}
                          </IconButton>
                        </Tooltip> : undefined}
                        <Link to={`/user/profile/${vs?.spotterUserId}`}>
                          <Tooltip title={`Gespot door ${vs?.spotterUserName}`}>
                            <IconButton
                                style={{ padding: 0 }}
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
                                <Camera />
                            </IconButton>
                          </Tooltip>
                        </Link>
                      </div>
                    }
                />
                </ImageListItem>
              );
            }))}

            <AddPhotoDialog countryCode={countryCode} registration={registration} vehicle={vehicle} />
          </ImageList>
        </div>
    );
}